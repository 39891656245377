#projects {
    position: relative;
}

.color-container {
    border-color: rgb(163, 163, 163);
    background: rgb(250, 250, 250);
}

.project-img {
    border-radius: 2rem;
    width: 90%;
    height: 90%
}

.project-title {
    margin: 1rem;
    color: black;
}

.project-btn {
    color: black;
    border-color: rgb(163, 163, 163);
}