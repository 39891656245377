#contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 70vh;
}

.contact-info-upper-container {
    display: flex;
    justify-content: center;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: rgb(163, 163, 163);
    background: rgb(250, 250, 250);
    margin: 2rem auto;
    padding: 0.5rem;
}

.contact-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 1rem;
}

.contact-info-container p {
    font-size: larger;
}

.contact-icon {
    cursor: default;
}

.email-icon {
    height: 2.5rem;
}