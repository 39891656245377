#about {
    position: relative;
}

.about-pic {
    border-radius: 2rem;
}

.section__pic-container {
    height: 400px;
    width: 400px;
    margin: auto 0;
}