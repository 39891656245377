#profile {
    display: flex;
    justify-content: center;
    gap: 5rem;
    height: 80vh;
}

.profile-pic {
    border-radius: 50%;
}

.title {
    font-size: 3rem;
    text-align: center;
}

#socials-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;
}

